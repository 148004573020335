import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import renderListOfImages from "./RenderListOfImages";
import {
  LinkInstagram,
  LinkOfficial,
  LinkOpenChat,
} from "../Constant/constant";
import RenderListOfImages from "./RenderListOfImages";
import RenderHeader from "./RenderHeader";
import RenderSetOfButton from "./RenderSetOfButton";
import RenderFooter from "./RenderFooter";
import RenderImageWithDetails from "./RenderImageWithDetails";

export default function LandingPage() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: MainTheme.colors.third[900],
      py: 5,
      px: 5,
    },
    subContainer: { justifyContent: "center", alignContent: "center" },
    header: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-start",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
    footer: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-end",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2} style={styles.subContainer}>
        <Grid xs={12} md={8} style={styles.header}>
          #bn2much
        </Grid>
        <Box sx={{ display: "none" }}>
          <h1>
            Bn2much รับซื้อ-ขาย-แลกเปลี่ยน กระเป๋าแบรนด์เนม กระเป๋าแบรนด์เนม
            มือสอง ของแท้ เชียงใหม่ รับซื้อถึงที่ Lv Gucci chanel fundi hermes
          </h1>
        </Box>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <RenderHeader />
        <RenderSetOfButton />
        <RenderListOfImages />
        {/* <RenderImageWithDetails /> */}
        <RenderFooter />
      </Grid>

      {/* footer */}
    </Box>
  );
}
