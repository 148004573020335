import { Typography } from "@mui/material";
import MainTheme from "../../Constant/colors";

type props = {
  text: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export default function TextBase(props: props) {
  const { text, variant = "h5" } = props;

  const styles = {
    headingLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[200],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 300,
    },
  };

  return (
    <Typography variant={variant} gutterBottom style={styles.headingLogo}>
      {text}
    </Typography>
  );
}
