import { Box, Link } from "@mui/material";
import MainTheme from "../../Constant/colors";

type props = {
  source: string;
  link: string;
  customStyle?: object;
};

export default function ImageBase(props: props) {
  const { source, link, customStyle } = props;
  const styles = useStyles();

  return (
    <Link href={link}>
      <Box
        component="img"
        src={source}
        sx={customStyle ?? styles.bodyImage}
        loading="lazy"
      />
    </Link>
  );
}

const useStyles = () => ({
  bodyImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: MainTheme.colors.primary[300],
    flex: 1,
    maxWidth: "250px",
    resizeMode: "contain",
  },
});
