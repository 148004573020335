import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { LinkInstagram } from "../Constant/constant";
import packageJson from "../../package.json";
import TextBase from "./atoms/TextBase";
import ImageBase from "./atoms/ImageBase";

export default function RenderFooter() {
  const styles = {
    body1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
    },
    headingLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[200],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 300,
    },
    bodyImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      maxWidth: "250px",
      resizeMode: "contain",
    },
    subContainer: { justifyContent: "center", alignContent: "center" },
    footer: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-end",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
  };

  const currentYear = dayjs().year();

  React.useEffect(() => {
    console.log(`App Version: ${packageJson.version}`);
  }, []);

  return (
    <>
      <Grid container direction="column" style={styles.body1} mb={3}>
        <Grid container justifyContent="center" flexDirection={"column"}>
          <TextBase text="Bn2much รับซื้อ-ขาย-แลกเปลี่ยน กระเป๋าแบรนด์เนม" />
          <TextBase text="กระเป๋าแบรนด์เนม มือสอง ของแท้" />
          <TextBase text="เชียงใหม่ รับซื้อถึงที่" />
        </Grid>
        <Grid container justifyContent="center"></Grid>
        <Grid container justifyContent="center">
          <ImageBase
            source={require("../Constant/img/thumbNailLineSquare.png")}
            link={LinkInstagram}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={styles.subContainer}>
        <Grid xs={12} md={8} style={styles.footer}>
          © {currentYear}, Designed by Bn2much
        </Grid>
      </Grid>
    </>
  );
}
